import React, { useState, useEffect } from 'react'

import { ActivityTile } from '../../Authoring/EditActivity/styles'
import DragNDropList from '../../DragNDropList'

// components:
import { UploadImage, PublishActivityChangesBtn } from '../../index'

// import { Link } from "gatsby"
// import Button from 'react-bootstrap/Button'

// hooks:
import { useGETEditActivityDetails } from '../../../hooks/requests/index'

// a form to edit an activity node
const EditActivity = ({ activityId }) => {
    const [activity, setActivity] = useState(() => {});
    const [publishBtnDisabled, setPublishBtnDisabled] = useState(true);
    const [listItems, updateListItems] = useState([]);          // DND list of activity->step_photographs
    const [loadingStatusMessage, setLoadingStatusMessage] = useState('Loading activity...')

    // convenience function to update activity (and keep it in sync with the DND step_photographs list) 
    const updateActivityStepPhotographs = (newItems) => {
        if (!activity) {
            // skip, until activity is acctually populated by GET request
            return
        }
        const newActivity = { ...activity }
        newActivity.relations.field_step_photographs = newItems
        setActivity(newActivity)
    }

    useEffect(() => {
        if (listItems.relations) {
            console.log('in useEffect. listItems: ', listItems.relations.field_step_photographs)
        }
        
        updateActivityStepPhotographs(listItems)
    }, [listItems]);

    const GETEditActivityDetails = useGETEditActivityDetails();

    GETEditActivityDetails(activityId, setActivity, updateListItems, setLoadingStatusMessage, activity)

    const serverUrl = process.env.GATSBY_BACKEND_SERVER_URL
    if (activity) {
        console.log()
        const featureImgUrl = serverUrl + activity.relations.field_activity_feature_photo.relations.field_photo_images.fileUrl
        const stepPhotos = activity.relations.field_step_photographs

        // adds: set the 'published' btn to disabled = false and update activity->step_photographs list
        const updateListItemsHijacked = (setPayload) => {
            updateListItems(setPayload)
            setPublishBtnDisabled(false)
 
            // can't setActivity here, because a function may be passed in and this can't be handled. 
            // updateActivityStepPhotographs(setPayload)
        }
        return (
            <div>
                <>
                    name: { activity.title }
                    <br />feature photograph: [goes here]
                    {/* <br /><img width="450" spublishrc={ featureImgUrl } /> */}
                    <br />body:
                </>
    
                <br /><br />Step photographs:
                {/* <br /><input id="filepicker" type="file" multiple onChange={(e) => uploadOnChange(e)} /> */}
                <br /><UploadImage updateListItemsHijacked={updateListItemsHijacked} />
                <PublishActivityChangesBtn publishBtnDisabled={publishBtnDisabled} setPublishBtnDisabled={setPublishBtnDisabled} activity={activity} />
                <DragNDropList listItems={listItems} updateListItems={updateListItems} listName="activities">
                    {(item, index) => (
                        <ActivityTile>
                            <div>
                                #{index+1}
                            </div>
                            <div>
                                img div
                                {/* <br /><img width="300" src={curPhotoUrl} />  */}
                            </div>
                            <div>
                                {item.title}
                            </div>
                        </ActivityTile>
                    )}
                </DragNDropList>
                
            </div>
        )
    } else {
        return (
            <>
                {loadingStatusMessage}
            </>
        )
    }
}

export default EditActivity;