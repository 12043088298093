import { useAxiosCall } from '../useAxiosCall'
import { NodeHydrator, IncludedRelationsBuilder } from '../../classes/index'

// makes a GET request to a JsonApi endpoint (e.g. drupal),
// and then (automatically) merges the returned "included" relations into each node's relationships,
// creating a tree (from the originally flat structure that was returned) (but recursively processing each sub relation)
// param: 'requestedRelations' specifies the nodes to include in the returned response (and allows requesting sub-relationships)
// example: const requestedRelations = {
//     'field_activity_feature_photo': null,
//     'field_step_photographs': {
//         // the relations "sub-relation" item/s (i.e. going a layer deeper):
//         'field_photo_images': null
//     }
// }
// Returns: a tree structure of objects / nodes
// note: NodeHydrator contains all the hydration logic
// customOnSuccess() is used to execute code once the node has been hydrated - ussually just to assign it to a useState value in the component.
// note: must return the func, as it will be used within useEffect() hook (and hooks cannot run inside a callback)
// skipExecution: populate this with the useState of the node, otherwise the setState() called inside the customOnSuccess() will be a continuous loop.
export const useJsonApiGet = () => {
    const axiosCall = useAxiosCall();

    const jsonApiGet = (nodeType, nodeId, requestedRelations, customOnSuccess, onFailure, skipExecution = null) => {
        if (skipExecution) {
            return null
        }
        
        const backendServerUrl = process.env.GATSBY_BACKEND_SERVER_URL
        
        console.log('== In useJsonApiGet() (#ssd3). nodeType: "' + nodeType +'", id: ', nodeId)
    
        var includeParam
        if (requestedRelations) {
            const IRBuilder = new IncludedRelationsBuilder()
            const includesArr = IRBuilder.getIncludeUrlParam(requestedRelations);
            includeParam = '?include=' + includesArr.join(',')
        } else {
            includeParam = ''
        }

        // Postman: 12.2 Get Activity & INCLUDE feature photo
        var url = backendServerUrl +
            '/jsonapi/node/' + nodeType
            + '/' +
            nodeId + includeParam
    
        console.log('attempting to get activity with id: (#ssd3): ', nodeId)
    
        // test building a hydrator class to resolve circular dependency.
    
        const onSuccess = (response) => {
            // const activity1 = new Activity()
            // activity1.populateViaAxiosResponse(response.data)
    
            const hydrator = new NodeHydrator(response.data, requestedRelations)
            const newNode = hydrator.getObjects()
    
            customOnSuccess(newNode)
    
            return newNode
        }
        
        // const onFailure = () => {
        //     customOnFailure()
        //     // note: error handling is already in useAxiosCall
        //     return null
        // }
        
        return axiosCall (url, 'get', null, onSuccess, onFailure)
    }
    
    return jsonApiGet
};