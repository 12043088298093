import DrupalNode from './DrupalNode'

export class Activity extends DrupalNode {
    constructor() {
        super()
    }

    populateViaJsonApiData (axiosData) {
        // console.log('[Activity] axiosData ee4: ', axiosData)
        DrupalNode.prototype.basicPopulate.call(this, axiosData)

        console.log('[Activity] new class: ', this)
    }
}