import React, { useState } from 'react'

// node classes
import { Photograph } from '../../classes/Node'

// hooks:
import { useJsonApiGet, useUploadNewFile } from '../../hooks/requests/index'

// render a file <input> (upload). onChange will send the new file to the server as binary, create a new Photograph node (via JsonApi)
// and update the activity step_photographs list.
export const UploadImage = ({ updateListItemsHijacked }) => {
    const jsonApiGet = useJsonApiGet()
    const uploadNewFile = useUploadNewFile()
    const [uploadStatusMsg, setUploadStatusMsg] = useState(false)

    const addTestPhotoNodeToList = () => {
        const newPhoto = new Photograph()
        newPhoto.title = 'test 1234'

        addItemToStepPhotographsList(newPhoto)
    }

    // add a photo to the activity's 'stepPhotograph' list
    // updates the order of "step photographs" within the activity object & change the 'Publish' button status to "visible"
    const addItemToStepPhotographsList = (newPhoto) => {
        updateListItemsHijacked(
            (prevState) => {
                // populate the new Photograph object.
                console.log('prevState: ', prevState)
                prevState.push(newPhoto)
                const newState = prevState.map((node) => node)

                setUploadStatusMsg(false)

                return newState
            }
        )

        return true
    }
    
    // Upload image file to drupal, create Photo node (using new file--file node id),
    // the: GET new;y created Photo node and FINALLY add the new photo node to activity->stepPhotos list
    const uploadOnChange = (e) => {
        setUploadStatusMsg('uploading image and processing...')
        //   alert('asdf123')
        // document.querySelector('#filepicker').addEventListener('change', async e => {
        console.log('e.target: ', e.target)
        // console.log('e.target.val: ', e.target.val)
        // console.log('e.target.value: ', e.target.value)
        let files = Array.from(e.target.files)
        console.log('upload file/s: ', files)

        // get the input element directly, (to set value to: "" later),
        // (note: don't use: 'e', as it will change to a "synthetic event" and lose the "target" attribute)
        const inputEle = e.target

        // create the Photograph node and add it to the activity->stepPhotographs list
        const onPhotoNodeCreateSuccess = (axiosData) => {
            console.log('in onPhotoNodeCreateSuccess():  axiosData: ', axiosData)

            // GET the new photograph node (via jsonApi)
            const newNodeId = axiosData.data.data.id

            const onGETSuccess = (newPhoto) => {
                console.log('in onGETSuccess(). newPhoto: ', newPhoto)
                addItemToStepPhotographsList(newPhoto)
                console.log('event: ', e)
                inputEle.value = ''
            }
            const onGETFailure = (e) => {
                console.log('====== there was an error: ', e)
                setUploadStatusMsg('there was an error')
            }
            
            const requestedRelations = { 'field_photo_images': null }
            jsonApiGet('photograph', newNodeId, requestedRelations, onGETSuccess, onGETFailure)
        }

        const result1 = uploadNewFile(files[0], onPhotoNodeCreateSuccess)
    }

    return (
        <div>
            <input id="filepicker" type="file" multiple onChange={(e) => uploadOnChange(e)} />
            &nbsp;
            <button type="button" className="btn btn-primary" onClick={addTestPhotoNodeToList}>Test create photograph</button>
            <br />
            { uploadStatusMsg &&
                <>
                    {uploadStatusMsg}
                </>
            }
        </div>
    )
}