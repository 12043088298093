import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { CustomList } from './styles'

// a list of items that can be reordered by the user through drag and drop interactions
// see tutorial: https://www.youtube.com/watch?v=aYZRRyukuIw&t=210s
const DragNDropList = ({ listName, listItems, updateListItems,  children }) => {
    useEffect(() => {
        console.log('listItems: ', listItems)

        // allows the "main app" using this component to do things like #1: update the original obj with the new order of the 'step_photographs' array
        // and #2: mark a button as "publish" (when there are changes to this DNDList)
        // onRefresh()
    })

    const handleOnDragEnd = (result) => {
        console.log('DragNDropList(): in handleOnDragEnd(). result: ', result)
        if (!result.destination) return
        console.log('Original list order rr5: ', listItems)

        const items = Array.from(listItems)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        console.log('New list order rr5: ', items)

        updateListItems(items)
    }
    console.log('listItems (ss4): ', listItems)
    return (
        <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={listName}>
                    {(provided) => (

                        <CustomList className={listName} {...provided.droppableProps} ref={provided.innerRef}>
                            {listItems && listItems.map((curItem, index) => {
                                // console.log('index: ', String(index))
                                // const id = toString(index)
                                const id = String(index)
                                // console.log('id: ' + id + ', index: ' + index)
                                // const curPhotoUrl = serverUrl + curPhoto.relations.field_photo_images.fileUrl
                                const curTitle =  curItem.title
                                // console.log('cur Photo: ', curItem)
                                return (
                                    <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) => (
                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                {children(curItem, index)}
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </CustomList>
                    )}
                </Droppable>
            </DragDropContext>
            
        </div>
        
    )
    
};

export default DragNDropList;