import { useAxiosCall } from '../useAxiosCall'
import { Photograph } from '../../classes/Node'

var exifr = require("exifr");

// returns a function that uploads a file to drupal (in binary / octet-stream format)
// [see postman: Bucktlist.com (Drupal 8) > 10.1 Upload image file (other)]
export const useCreateNewPhotographNode = () => {
    const axiosCall = useAxiosCall();
    
    // upload the file as file--file node, then create a Photograph node.
    const createNewPhotoNode = async (imageFile, fileNodeId, customOnSuccess, md5FileHash) => {
        console.log('fileNodeId: ', fileNodeId)
        
        var exif_data_all
        await exifr.parse(imageFile).then((output) => {
            console.log("Camera: ", output)
            exif_data_all = btoa(JSON.stringify(output))
            console.log('serialized: ', exif_data_all)
        })
        // console.log('btoa exif_data_all: ', btoa(exif_data_all).count)
        // console.log('btoa exif_data_all: ', btoa(exif_data_all))
        // console.log('btoa exif_data_all: ', + exif_data_all)
        // console.log('btoa exif_data_all: ', btoa('NaN'))
        // console.log('btoa exif_data_all: ', atob('W29iamVjdCBPYmplY3Rd'))

        // console.log('btoa exif_data_all: ', btoa('cats 123 '))
        // console.log('btoa exif_data_all: ', atob(''))

        var gps = await exifr.gps(imageFile);
        console.log("gps #dd5 ", gps);

        const lat = (gps) ? gps.latitude : null
        const long = (gps) ? gps.longitude : null

        // console.log('result1: ', result1)

        const newPhoto = new Photograph()
        newPhoto.title = 'untitled'

        const filename = imageFile.name

        console.log('exif_data_all: ', exif_data_all)

        const photoNodeBody = {
            data: {
                type: "node--photograph",
                attributes: {
                    title: newPhoto.title,
                    body: "body 123",
                    field_is_4x3_available: 1,
                    field_gps_latitude: lat,
                    field_gps_longitude: long,
                    field_exif_data_serialized: exif_data_all,
                    field_md5_file_hash: md5FileHash
                },
                "relationships": {
                    "field_photo_images": {
                        "data": {
                            "type": "file--file",
                            "id": fileNodeId,
                            "meta": {
                                "alt": "image alt text 123",
                                "title": imageFile.name,
                                "width": null,
                                "height": null
                            }
                        }
                    }
                }
            }
        }

        const onSuccess = (response) => {
            console.log('Successfully created new Photo node (via json API)')

            customOnSuccess (response)
        }

        const onFailure = () => {
            console.log('Failed creating new Photo node.')
        }

        const postPhotographNodeToJsonApi = (payload) => {
            var url = process.env.GATSBY_BACKEND_SERVER_URL +
            '/jsonapi/node/photograph'

            return axiosCall (url, 'post', JSON.stringify(payload), onSuccess, onFailure)
        }

        postPhotographNodeToJsonApi(photoNodeBody, )
    }
    
    return createNewPhotoNode
}