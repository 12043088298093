// convert the provided file (collected directly from an <input> element) to binary output
// (for use in for example: being uploaded to Drupal as a file via axios [see postman: Bucktlist.com (Drupal 8) > 10.1 Upload image file (other)])
export const getFileAsBinary = (file, callBack) => {
    // generate binary from the file / image 
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
        const payload = reader.result;
        callBack(file, payload)
        
    }
}