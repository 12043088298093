import { useAxiosCall } from '../useAxiosCall'

// node:
import { Activity } from '../../classes/Node'

export const usePatchActivity = () => {
    const axiosCall = useAxiosCall();
    
    const PATCHActivity = async (activity, customOnSuccess) => {
        console.log('activity: ', activity)

        const listData = activity.relations.field_step_photographs.map((item) => {
            console.log('item: ', item)
            return {
                "type": "node--photograph",
                "id":  item.id
            }
        })

        // continue from here: get PATCH request working correctly.
        
        const activityPayload = {
            data: {
                id: activity.id,
                type: "node--activity",
                attributes: {
                    title: activity.title
                },
                "relationships": {
                    "field_step_photographs": {
                        "data": listData
                    }
                }
            }
        }

        console.log('activityPayload: ', activityPayload)

        const onSuccess = (response) => {
            console.log('Successfully updated / PATCH activity: ', activity )

            customOnSuccess (response)
        }

        const onFailure = () => {
            console.log('Failed update activity: ', activity)
            alert ('there was an error')
        }

        const PATCHActivityNodeViaoJsonApi = (payload) => {
            var url = process.env.GATSBY_BACKEND_SERVER_URL +
            '/jsonapi/node/activity/' + activity.id

            return axiosCall (url, 'patch', JSON.stringify(payload), onSuccess, onFailure)
        }

        PATCHActivityNodeViaoJsonApi(activityPayload)
    }
    
    return PATCHActivity
}