import React, { useState } from 'react'

// hooks:
import { usePatchActivity } from '../../hooks/requests'

// Sends PATCH request to JsonAPi (to "update" an existing activity node.)
export const PublishActivityChangesBtn = ({ publishBtnDisabled, setPublishBtnDisabled, activity }) => {
    console.log('in publishChanges().', publishBtnDisabled)
    const PATCHActivity = usePatchActivity()

    const onSuccess = () => {
        setPublishBtnDisabled(true)

        // todo: notify user that the save was successful.
    }

    return (
        <>
            <button disabled={publishBtnDisabled} type="button" className="btn btn-primary" onClick={() => PATCHActivity(activity, onSuccess)}>Publish Changes</button>
        </>
    )
}