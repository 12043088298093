import { useAxiosCall } from '../useAxiosCall'
import { getFileAsBinary } from '../../functions'
import { useCreateNewPhotographNode } from '../requests'
import BMF from 'browser-md5-file'

var exifr = require("exifr")

// returns a function that uploads a file to drupal (in binary / octet-stream format)
// [see postman: Bucktlist.com (Drupal 8) > 10.1 Upload image file (other)]
export const useUploadNewFile = () => {
    const axiosCall = useAxiosCall();
    const createNewPhotoNode = useCreateNewPhotographNode()

    const uploadNewFile = (file, onPhotoNodeCreateSuccess) => {
        console.log('in uploadNewFile(). file: ', file)
    
        const onSuccess = (response, customPassThroughPayload) => {
            console.log('upload image response: ', response)
            console.log('customPassThroughPayload: ', customPassThroughPayload)
            // const activity1 = new Activity()
            // activity1.populateViaAxiosResponse(response.data)

            const newFileNodeId = response.data.data.id
            createNewPhotoNode(file, newFileNodeId, onPhotoNodeCreateSuccess, customPassThroughPayload.md5FileHash)
        }

        const onFailure = () => {
            // note: error handling is already in useAxiosCall
            alert ('error when trying to upload file')
            return null
        }

        // refactor - make below into hook?
        // use the binary "payload" provided to upload the file to Drupal.
        const uploadFileToDrupal = async (file, fileAsBinaryPayload) => {
            const filename = file.name
            const bmf = new BMF();

            // const wer = md5(fileAsBinaryPayload);
            // console.log('blue-imp: ', wer)

            var startTime = performance.now()
            const result1 = await bmf.md5(
                file,
                (err, md5FileHash) => {
                    var endTime = performance.now()
                    console.log(`[md5 hash] md5 hash of uploaded file took ${endTime - startTime} milliseconds`)
                    console.log('[md5 hash] err:', err);
                    console.log('[md5 hash] md5 string:', md5FileHash);
                    uploadFileToDrupal(md5FileHash)
                },
                progress => {
                  console.log('[md5 hash] progress number:', progress);
                }
            );

            console.log('[md5 hash] result1L: ', result1)

            const uploadFileToDrupal = (md5FileHash) => {
                var url = process.env.GATSBY_BACKEND_SERVER_URL +
                '/jsonapi/node/photograph/field_photo_images'
    
                const customHeaders = {
                    "Content-Type": "application/octet-stream",
                    "Accept": "application/vnd.api+json",
                    "Content-Disposition": 'file; filename="' + filename + '"'
                }

                const customPassThroughPayload = {
                    md5FileHash
                }
    
                return axiosCall (url, 'post', fileAsBinaryPayload, onSuccess, onFailure, customHeaders, customPassThroughPayload)
            }
            
        }
        getFileAsBinary(file, uploadFileToDrupal)
    }
    
    return uploadNewFile
}