import { useJsonApiGet } from './useJsonApiGet'

// get the activity info via jsonApi - and assign it to: setActivity() and updateListItems()
// the request used to get details for the 'Edit Activity' page.
export const useGETEditActivityDetails = () => {
    const jsonApiGet = useJsonApiGet();

    const useGETEditActivityDetails = (activityId, setActivity, updateListItems, setLoadingStatusMessage, activity) => {
        // get the hydrated activity and relevant sub-relations
        const requestedRelations = {
            'field_activity_feature_photo': {
                // the relations "sub-relation" item/s (i.e. going a layer deeper):
                'field_photo_images': null
            },
            'field_step_photographs': {
                'field_photo_images': null
            }
        }
        
        const onSuccess = (node) => {
            // console.log('setActivity: ', node)
            console.log('ee3 onSuccess() being called. Node: ', node)
            setActivity(node)
            updateListItems(node.relations.field_step_photographs)
        }

        const onFailure = (e) => {
            console.log('failure to load activity error response: ', e.response)
            setLoadingStatusMessage('unable to load activity plan with id: ' + activityId + '. HTTP status code: ' + e.response.status)
        }
        return jsonApiGet('activity', activityId, requestedRelations, onSuccess, onFailure, activity)
    }
    
    return useGETEditActivityDetails
}