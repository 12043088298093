import DrupalNode from './DrupalNode.js'

export class Photograph extends DrupalNode {
    constructor () {
        super()
    }

    populateViaJsonApiData(jsonApiData) {
        console.log('[Photograph] axiosData [#xxs2]: ', jsonApiData)
        DrupalNode.prototype.basicPopulate.call(this, jsonApiData)

        const { attributes } = jsonApiData
        this.field_gps_latitude = attributes.field_gps_latitude
        this.field_gps_longitude = attributes.field_gps_longitude
    }
}