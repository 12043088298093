import DrupalNode from './DrupalNode'

export class File extends DrupalNode {
    constructor() {
        super()
    }

    populateViaJsonApiData (axiosData) {
        // console.log('[File] axiosData rr3: ', axiosData)
        const { attributes } = axiosData
        DrupalNode.prototype.basicPopulate.call(this, axiosData)

        // this.id                     = axiosData.id
        // this.drupal_internal__nid   = attributes.drupal_internal__nid

        // this.title                  = attributes.title

        this.filename = attributes.filename
        this.fileUrl = attributes.uri.url

        // this.relations.field_activity_feature_photo = new Photograph()

        // console.log('[File] new class: ', this)
    }
}