// does some useful things like finding related nodes (from Json:api axiosData) by machine name, 
// automatically finding them in json:api "included" and then adding them to this class (for MUCH easier access).
class DrupalNode {
    // get the basic attributes and populate the "relations" element with "hollow" nodes.
    basicPopulate(axiosData) {
        console.log('zzxx2: ', axiosData)
        const { attributes, relationships } = axiosData

        this.id                     = axiosData.id
        this.drupal_internal__nid   = attributes.drupal_internal__nid

        this.title                  = attributes.title

        // create "hollow" elements on the "relationships" key to indicate that the relations exist, but they haven't been requested / arn't populated.
        // (note: this is helpful as otherwise the "possible" relationships on that node wouldn't be visible (unless they were requeted in the HTTP GET))
        // (note: if they were requested, they will be populated during hydration).
        this.relations = {}
        Object.keys(relationships).forEach(key => {
            const relation = relationships[key]
            console.log('relation: ', relation)
            this.relations[key] = 'not populated [marker:#tx3]'
        });
    }
}

export default DrupalNode