// this builds the "&include=" part of the request url to jsonApi
// it's accepts this shape:
// const requestedRelations = {
//     'field_activity_feature_photo': null,
//     'field_step_photographs': {
//         // the relations "sub-relation" item/s (i.e. going a layer deeper):
//         'field_photo_images': null
//     }
// }
// and outputs a string in the format: "relation,relation2,relation2.sub-relation1"
export class IncludedRelationsBuilder {
    constructor () {
        
    }

    // generates a url parameters like: 'field_activity_feature_photo,field_step_photographs,field_step_photographs.field_photo_images'
    getIncludeUrlParam (relations1, prefix = '') {
        if (prefix) {
            prefix = prefix + '.'
            console.log('ADDING prefix #44: ', prefix)
        }

        var includes = [];
        Object.keys(relations1).forEach(key => {
            const val = relations1[key]
            console.log('loop #ff2: ', key, val)

            // add the include to the array
            // note: do this even if it contains sub items (i.e. it's val is an obj)

            // add sub-items (if they exist)
            var newInclude = prefix + key    
            includes.push(newInclude)
            if (val instanceof Object) {
                const subprefix = prefix + key
                console.log('prefix #44: ', prefix)
                const additionalIncludes = this.getIncludeUrlParam(val, subprefix)
                includes = includes.concat(additionalIncludes)
            }
           
        });
        console.log('final result #44: ', includes);

        return includes
    }
}