import React, { useEffect } from "react"

import Layout from "components/Layout"
import SEO from "components/SEO"
// import { LoginFormWrapper } from "../components/UserSystem/LoginOnSubmit";
// import EXIF from "exif-js";

import handleAlerts from '../functions/handleAlerts'
import EditActivity from '../components/Authoring/EditActivity/index'

// import exifr from 'exifr/dist/full.esm'
var exifr = require("exifr");
// dist/lite.esm.js'

const LoginPage = ({ location }) => {
  useEffect(() => {handleAlerts(location)}, []);

  return (
    <Layout>
        <SEO title="Testing page" />

        <EditActivity activityId='7ed04730-c11c-42ad-bca6-e85c8b916509' />
        {/* <script type="module">
            import exifr from './node_modules/exifr/dist/lite.esm.js'
            document.querySelector('#filepicker').addEventListener('change', async e => {
                let files = Array.from(e.target.files)
                let exifs = await Promise.all(files.map(exifr.parse))
                let dates = exifs.map(exif => exif.DateTimeOriginal.toGMTString())
                console.log(`${files.length} photos taken on:`, dates)
            })
        </script> */}
      
    </Layout>
  )
}

export default LoginPage