import { Activity, Photograph, File } from './Node/index'

// this hydrates jsonApi data into objects (based on their nodeType).
// it builds (and returns) a tree structure of requested nodes (from the flat jsonApi structure) by recursively hydrating relations
// from the returned "included" element (of jsonApi data returned from the server.)
export class NodeHydrator {
    constructor (jsonApiData, requestedRelations) {
        this.jsonApiData = jsonApiData
        this.data = jsonApiData.data
        this.included = jsonApiData.included

        // these are the relations (and sub-relations) that have been requested along with the node). Items in this object will specifically be sought
        // in the JsonApi during hydration and if not found, will throw an error
        this.requestedRelations = requestedRelations

        // this is where we will store node objects that have been hydrated (for easier access)
        this.hydratedIncluded = {}
    }

    getObjects() {
        console.log('[nodeHydrator] jsonApiData [#ccs1]: ', this.jsonApiData)

        const hydratedObj = this.hydrateFromJsonApiData(this.jsonApiData.data, this.requestedRelations)

        console.log('==== hydrated object [#rr3]: ', hydratedObj)

        return hydratedObj
    }

    // create an object from the jsonApiData (based on its type) and populate it.
    hydrateNode (jsonApiData) {
        const nodeType = jsonApiData.type

        var newNode = null
        switch(nodeType) {
            case 'node--photograph':
                newNode = new Photograph(jsonApiData)
                newNode.populateViaJsonApiData(jsonApiData)
                break
            case 'node--activity':
                newNode = new Activity(jsonApiData)
                newNode.populateViaJsonApiData(jsonApiData)
                break
            case 'file--file':
                newNode = new File()
                newNode.populateViaJsonApiData(jsonApiData)
                break
            default:
                throw new Error ('Node of type: ' + nodeType + ' can not be hydrated - please write the code for this.')
        }

        return newNode
    }

    // hydrate all the relations specified in requestedRelations and add them to node.relations
    // calls itself recursively to hydrate sub-relations
    hydrateFromJsonApiData (jsonApiData, requestedRelations) {
        // console.log('===== starting new: hydrateFromJsonApiData() [#rr3] ====')
        // console.log('PARAMETERS: jsonApiData: ', jsonApiData, 'requestedRelations: ', requestedRelations )
        const nodeType = jsonApiData.type
        const title = jsonApiData.attributes.title
        // console.log('[#rr3]: type: ' + nodeType + ', title: "' + title + '": ', jsonApiData)

        var newNode = this.hydrateNode(jsonApiData)

        console.log('this.requestedRelations (#rr3): ', requestedRelations)

        // hydrate all the relations for this node (specified in requestedRelations)
        // (note: we're going to get relations from json:api "included" (and not the "data" element) and then pass this into a recursive function call to hydrate
        // -- as the "included" version contains the full dataset to hydrate into a node object.)
        console.log('requestedRelations rr3: ', requestedRelations)
        if (!requestedRelations) {
            return newNode
        }

        // hydrate requestedRelations (and sub-relations) recursively
        // these are the relations in the jsonApi "included" element
        Object.keys(requestedRelations).forEach(relationName => {
            console.log('getting relation (#rr3): ' + relationName + ', requestedRelations: ', requestedRelations)

            // const relationsJsonApiData = jsonApiData.data.relationships[relationName]
            // console.log('== jsonApiData.data.relationships [#rr3]: ', jsonApiData.data.relationships)
            // console.log('cur target relation [#rr3]: ', relationsJsonApiData)

            const nodeIds = this.getNodeIdByElementName(jsonApiData, relationName)
            // console.log('target nodeId [#rr3]: ' + nodeIds)
            const nodes = this.getNodeFromIncluded(nodeIds)
            // console.log('== nodes [#rr3]: ', nodes)
            
            const subItems = requestedRelations[relationName]

            if (subItems instanceof Object) {
                // todo zzzz
                // go multiple layers deep with this
            }

            // hydrate the relation (node or array or nodes)
            var curRelation
            if (nodes instanceof Array) {
                curRelation = nodes.map(curNode => {
                    return this.hydrateFromJsonApiData(curNode, subItems)    
                })
            } else {
                curRelation = this.hydrateFromJsonApiData(nodes, subItems)
            }

            console.log('curRelation: ', curRelation)
            console.log('newNode: ', newNode)

            if (!newNode.relations) {
                newNode.relations = []
            }

            // assign the node being hydrated with it's "included" relations
            console.log('setting: "' + relationName + '" (on: "' + title + '") to: ', curRelation)
            newNode.relations[relationName] = curRelation

            console.log('===== hydrated newNode [#rr3]: ', newNode)

        })

        return newNode

    }

    // get a node (or array of nodes) from this.included
    getNodeFromIncluded (nodeIds) {
        if (nodeIds instanceof Array) {
            const nodes = nodeIds.map(nodeId => {
                return this.findNodeByNodeId(nodeId)
            })

            return nodes
        }
        
        return this.findNodeByNodeId(nodeIds)
    }

    // get a (individual) node by its nodeId
    findNodeByNodeId(nodeIdNeedle) {
        console.log('nodeIdNeedle: ', nodeIdNeedle)
        console.log('this.jsonApiData: ', this.jsonApiData)
        const result = this.jsonApiData.included.find(
            element => element.id == nodeIdNeedle
        )

        if (!result) {
            throw new Error ('unable to find a node with the id: ' + nodeIdNeedle + ' in the "included" json api data') 
        }
        return result
    }

    // find and return the nodeId of a node (or array of node Ids if it's a collection of nodes), finding the node/s via its "machine" name
    getNodeIdByElementName(jsonApiData, nodeMachineName) {
        // "hollow" is because it only contains the id, not the node data.
        const hollowNode = jsonApiData.relationships[nodeMachineName]

        if (!hollowNode) {
            throw new Error('a related node with machine name: "' + nodeMachineName + '" does not exist in the relationships of the jsonApiData provided: ', jsonApiData)
        }

        // check if the data is an array, then it's an array of relations (not a single one)
        if (hollowNode.data instanceof Array) {
            console.log('relation: ' + nodeMachineName + ' is an array of nodes [#rr3].')

            // build an array of nodeIds that exist in this relation
            const nodeIds = hollowNode.data.map(node => node.id)

            console.log('nodeIds array [#rr3]: ', nodeIds)
            
            return nodeIds
        }

        const nodeId = hollowNode.data.id
        console.log('nodeId (of node machine name: "' + nodeMachineName + '") is [#]: ' + nodeId)
        

        return nodeId
    }
}
